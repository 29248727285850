export const userMenus = [
  {
    name: "Dashboard",
    path: "/user/dashboard",
    icon: "pi pi-home",
  },
  {
    name: "Transaksi",
    path: "/user/transaction",
    icon: "pi pi-receipt",
  },
  {
    name: "Nomor WhatsApp",
    path: "/user/number",
    icon: "pi pi-whatsapp",
  },
  {
    name: "Broadcast",
    path: "/user/broadcast",
    icon: "pi pi-bolt",
  },
  {
    name: "Status",
    path: "/user/status",
    icon: "pi pi-clone",
  },
  {
    name: "Affiliate",
    path: "/user/affiliate",
    icon: "pi pi-dollar",
  },
  {
    name: "Pengaturan Akun",
    path: "/user/setting",
    icon: "pi pi-cog",
  },
  {
    name: "Dokumentasi API",
    path: "/user/documentation",
    icon: "pi pi-clipboard",
  },
];

export const adminMenus = [
  {
    name: "Dashboard",
    path: "/kanrisha/dashboard",
    icon: "pi pi-home",
  },
  {
    name: "Transaksi",
    path: "/kanrisha/transaction",
    icon: "pi pi-receipt",
  },
  {
    name: "User",
    path: "/kanrisha/user",
    icon: "pi pi-user",
  },
  {
    name: "Affiliate",
    path: "/kanrisha/affiliate",
    icon: "pi pi-dollar",
  },
  {
    name: "Artikel",
    path: "/kanrisha/article",
    icon: "pi pi-book",
  },
  {
    name: "Pengaturan Akun",
    path: "/kanrisha/setting",
    icon: "pi pi-cog",
  },
];
